import Loadable from 'react-loadable';
import Loading from '@/components/Loading'

const Dashboard = Loadable({loader: () => import('@/views/dashboard'),loading: Loading});
const Order = Loadable({loader: () => import('@/views/order'),loading: Loading});
const Error404 = Loadable({loader: () => import('@/views/error/404'),loading: Loading});
const Level1 = Loadable({
  loader: () => import('@/views/level/level1'),
  loading: Loading,
});
const Level2 = Loadable({
  loader: () => import('@/views/level/level2'),
  loading: Loading,
});
const Level3 = Loadable({
  loader: () => import('@/views/level/level3'),
  loading: Loading,
});
const Lession = Loadable({
  loader: () => import('@/views/level/level4'),
  loading: Loading,
});
const User = Loadable({
  loader: () => import('@/views/user'),
  loading: Loading,
});

const Question = Loadable({
  loader: () => import('@/views/knowledge/questions'),
  loading: Loading,
});
const Answer = Loadable({
  loader: () => import('@/views/knowledge/answers'),
  loading: Loading,
});
const Teacher = Loadable({
  loader: () => import('@/views/knowledge/teachers'),
  loading: Loading,
});
const Search = Loadable({
  loader: () => import('@/views/knowledge/search'),
  loading: Loading,
});

const Blog = Loadable({
  loader: () => import('@/views/blog'),
  loading: Loading,
});
const Grammar = Loadable({
  loader: () => import('@/views/grammar'),
  loading: Loading,
});

const Vocabulary = Loadable({
  loader: () => import('@/views/vocabulary'),
  loading: Loading,
});
const Practice = Loadable({
  loader: () => import('@/views/practice'),
  loading: Loading,
});
const Notification = Loadable({
  loader: () => import('@/views/notification'),
  loading: Loading,
});
const Qa = Loadable({
  loader: () => import('@/views/qa'),
  loading: Loading,
});

const Member = Loadable({
  loader: () => import('@/views/member'),
  loading: Loading,
});
const Practice50 = Loadable({
  loader: () => import('@/views/practice50'),
  loading: Loading,
});
const Talk = Loadable({
  loader: () => import('@/views/talk'),
  loading: Loading,
});
const Option = Loadable({
  loader: () => import('@/views/option'),
  loading: Loading,
});
const FreeTextbook = Loadable({
  loader: () => import('@/views/free-textbook'),
  loading: Loading,
});
const Tracking = Loadable({
  loader: () => import('@/views/tracking'),
  loading: Loading,
});
const Mail = Loadable({loader: () => import('@/views/mail'),loading: Loading});
const MailLog = Loadable({loader: () => import('@/views/mail-log'),loading: Loading});
const Seminar = Loadable({loader: () => import('@/views/seminar'),loading: Loading});
const Schedule = Loadable({loader: () => import('@/views/schedule'),loading: Loading});

export default [
  { path: '/dashboard', component: Dashboard },
  { path: '/orders', component: Order },
  { path: '/levels/level1', component: Level1 },
  { path: '/levels/level2', component: Level2 },
  { path: '/levels/level3', component: Level3 },
  { path: '/lessons', component: Lession },

  { path: '/knowledge/questions', component: Question },
  { path: '/knowledge/answers', component: Answer },
  { path: '/knowledge/teachers', component: Teacher },
  { path: '/knowledge/search', component: Search },

  { path: '/blogs', component: Blog },
  { path: '/grammars', component: Grammar },
  { path: '/vocabularies', component: Vocabulary },
  { path: '/practices', component: Practice },
  { path: '/notifications', component: Notification },
  { path: '/qas', component: Qa },
  { path: '/members', component: Member },
  { path: '/practices50', component: Practice50 },
  { path: '/talks', component: Talk },
  { path: '/free-textbook', component: FreeTextbook },
  { path: '/seminar', component: Seminar },
  { path: '/schedules', component: Schedule },
  { path: '/tracking', component: Tracking },

  // { path: '/options', component: Option },

  { path: '/users', component: User },
  { path: '/mails', component: Mail },
  { path: '/mail-logs', component: MailLog },
  { path: '/error/404', component: Error404 },
];
