import React from 'react';
import {
  DashboardOutlined,
  DatabaseOutlined,
  CalendarOutlined,
  UsergroupAddOutlined,
  CheckCircleOutlined,
  MailOutlined,
  ReadOutlined,
  BookOutlined,
  VideoCameraOutlined,
  QuestionCircleOutlined,
  BankOutlined,
  FireOutlined,
  SettingOutlined,
  ContactsOutlined,
  MoneyCollectOutlined,
  GiftOutlined,
  PieChartOutlined,
  QuestionOutlined,
  UserOutlined,
  FileSearchOutlined,
  CloudServerOutlined,
  HddOutlined,
  SketchOutlined,
  ShoppingOutlined,
  NotificationOutlined,
  WechatOutlined,
  SendOutlined,
  BulbOutlined,
  ScheduleOutlined,
  FundOutlined
} from '@ant-design/icons';
  
  import * as PropTypes from 'prop-types';
  
  const MenuIcon = ({ type, ...rest }) => {
    const getIcon = (iconType) =>
      ({
        DashboardOutlined: <DashboardOutlined {...rest} />,
        DatabaseOutlined: <DatabaseOutlined {...rest} />,
        CalendarOutlined: <CalendarOutlined {...rest} />,
        UsergroupAddOutlined: <UsergroupAddOutlined {...rest} />,
        CheckCircleOutlined: <CheckCircleOutlined {...rest} />,
        MailOutlined: <MailOutlined {...rest} />,
        ReadOutlined: <ReadOutlined {...rest} />,
        BookOutlined: <BookOutlined {...rest} />,
        VideoCameraOutlined: <VideoCameraOutlined {...rest} />,
        QuestionCircleOutlined: <QuestionCircleOutlined {...rest} />,
        BankOutlined: <BankOutlined {...rest} />,
        FireOutlined: <FireOutlined {...rest} />,
        SettingOutlined: <SettingOutlined {...rest} />,
        ContactsOutlined: <ContactsOutlined {...rest} />,
        MoneyCollectOutlined: <MoneyCollectOutlined {...rest} />,
        GiftOutlined: <GiftOutlined {...rest} />,
        PieChartOutlined: <PieChartOutlined {...rest} />,
        QuestionOutlined: <QuestionOutlined {...rest} />,
        UserOutlined: <UserOutlined {...rest} />,
        FileSearchOutlined: <FileSearchOutlined {...rest} />,
        CloudServerOutlined: <CloudServerOutlined {...rest} />,
        HddOutlined: <HddOutlined {...rest} />,
        SketchOutlined: <SketchOutlined {...rest} />,
        ShoppingOutlined: <ShoppingOutlined {...rest} />,
        NotificationOutlined: <NotificationOutlined {...rest} />,
        WechatOutlined: <WechatOutlined {...rest} />,
        SendOutlined: <SendOutlined {...rest} />,
        BulbOutlined: <BulbOutlined {...rest} />,
        ScheduleOutlined: <ScheduleOutlined {...rest} />,
        FundOutlined: <FundOutlined {...rest} />,
      }[iconType]);
  
    return getIcon(type) || <DashboardOutlined {...rest} />;
  };
  
  MenuIcon.propTypes = {
    type: PropTypes.string,
  };
  
  export default MenuIcon;